/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

@font-face {
  font-family: 'Nanum Square';
  font-weight: normal;
  src: url('lib/fonts/nanum-square/NanumSquareR.ttf') format('woff2'),
  url('lib/fonts/nanum-square/NanumSquareR.ttf') format('woff'),
  url('lib/fonts/nanum-square/NanumSquareR.ttf') format('truetype');
}

@font-face {
  font-family: 'Nanum Square';
  font-weight: bold;
  src: url('lib/fonts/nanum-square/NanumSquareB.ttf') format('woff2'),
  url('lib/fonts/nanum-square/NanumSquareB.ttf') format('woff'),
  url('lib/fonts/nanum-square/NanumSquareB.ttf') format('truetype');
}

@font-face {
  font-family: 'Nanum Square';
  font-weight: 900;
  src: url('lib/fonts/nanum-square/NanumSquareEB.ttf') format('woff2'),
  url('lib/fonts/nanum-square/NanumSquareEB.ttf') format('woff'),
  url('lib/fonts/nanum-square/NanumSquareEB.ttf') format('truetype');
}

body {
  /*box-sizing: border-box;*/
  /*font-family: 'Noto Sans KR', sans-serif;*/
  font-family: 'Nanum Square', sans-serif;
  font-weight: normal;
  font-size: 14px;
}

._spinner {
  z-index: 99999;
  background-color: rgba(255,255,255,0.6);
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#__react-alert__ {
  position: fixed !important;
  z-index: 9999 !important;
}
#__react-alert__ div div div {
  /*padding-top: 0px !important;*/
  /*padding-bottom: 0px !important;*/
  width: auto !important;
}